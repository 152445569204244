import styled from 'styled-components';

export const Background = styled.div`
    width: 100%;
    height: auto;
    margin-top: -372px;
`;

export const Header = styled.div`
    width: 100%;
    margin-bottom: 120px;
    position: relative;

    @media all and (max-width: 768px) {
        margin-bottom: 28px;
    }
`;

export const HeaderContent = styled.div`
    z-index: 101;
`;

export const Div = styled.div`
    padding: ${({ padding }) => padding || 0};
    margin: ${({ margin }) => margin || 0};

    @media all and (max-width: 768px) {
        padding: 3em 0 0 0;
    }
`;

export const ContentInner = styled.div`
    padding: ${({ padding }) => padding || 0};
`;

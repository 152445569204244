import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import { colors } from '../../../../theme';

import { Background } from './styled';

import { customTooltips, openTip, closeTip } from './utils';

const MyChart = ({ values, src }) => {
    const ChartRef = useRef();

    const [showTooltipIndex, setShowTooltipIndex] = useState(0);

    // useEffect(() => {
    //     const { chartInstance } = ChartRef.current;
    //     function intervalFunction() {
    //         closeTip(chartInstance, 0, showTooltipIndex);
    //         let nextIndex = showTooltipIndex + 1;

    //         if (nextIndex > values.value.length) {
    //             nextIndex = 1;
    //         }
    //         setShowTooltipIndex(nextIndex);
    //         openTip(chartInstance, 0, nextIndex);
    //     }

    //     const interval = setInterval(intervalFunction, 4000);
    //     return () => {
    //         clearInterval(interval);
    //         closeTip(chartInstance, 0, showTooltipIndex);
    //     };
    // }, [showTooltipIndex, values.value, ChartRef]);

    const data = useMemo(
        () => ({
            labels: [0, ...values.value.map(value => value.id), 0],
            datasets: [
                {
                    data: [-140, -140, -120, -125, -100, -53, -40],
                    fill: true,
                    backgroundColor: 'white',
                    borderColor: colors.blue,
                    lineTension: 0,
                    pointBackgroundColor: colors.blue,
                    pointRadius: 8,
                },
            ],
        }),
        [values]
    );

    const options = {
        events: [],
        scales: {
            yAxes: [{ display: false, ticks: { min: -200, max: 0 } }],
            xAxes: [{ display: false }],
        },
        legend: {
            display: false,
        },
        // tooltips: {
        //     enabled: false,
        //     mode: 'index',
        //     custom: tooltip => customTooltips(tooltip, values),
        // },
        animation: {
            duration: 0,
        },
    };

    return (
        <Background src={src}>
            <Line data={data} options={options} ref={ChartRef} />
        </Background>
    );
};
MyChart.propTypes = {
    src: PropTypes.string.isRequired,
    values: PropTypes.shape({
        value: PropTypes.arrayOf(
            PropTypes.shape({
                dateTime: PropTypes.string,
                title: PropTypes.string,
                valueType: PropTypes.string,
                mainValue: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
            })
        ),
    }).isRequired,
};

export default MyChart;

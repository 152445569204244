import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-simple-flex-grid';

import Button from '../../global/ButtonLink';
import Container from '../../global/Container';
import Section from '../../global/Section';
import Chart from './components/Chart';
import { Heading1 } from '../../global/Typography';
import { Background, Header, HeaderContent, Div } from './styled';

import { getAlignment } from './helpers';

const Homepage = ({ data, lng }) => {
    return (
        <>
            <Header>
                <HeaderContent id="header-content">
                    <Container>
                        <Div padding="7em 0 0 0">
                            <Row justify="center">
                                <Col xs={12} md={10}>
                                    <Heading1 marginBottom="50px">{data.title}</Heading1>
                                    <Button
                                        label={data.button.label}
                                        href={data.button.href}
                                        lng={lng}
                                        isBig
                                    />
                                </Col>
                            </Row>
                        </Div>
                    </Container>
                </HeaderContent>
                <Background>
                    <Chart src={data.background.publicURL} values={data.chartData} />
                </Background>
            </Header>
            {data.sections.map((section, index) => (
                <Section
                    key={section.id}
                    section={section}
                    alignment={getAlignment(index, data.sections.length)}
                    lng={lng}
                />
            ))}
        </>
    );
};

Homepage.propTypes = {
    data: PropTypes.shape({
        background: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
        }).isRequired,
        button: PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        }).isRequired,
        chartData: PropTypes.shape({}),
        title: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.shape({ publicURL: PropTypes.string }),
                title: PropTypes.string,
                description: PropTypes.string,
                button: PropTypes.shape({
                    href: PropTypes.string,
                    label: PropTypes.string,
                }),
                image: PropTypes.shape({
                    publicURL: PropTypes.string,
                }),
            })
        ),
    }).isRequired,
    lng: PropTypes.string.isRequired,
};

export default Homepage;

import styled from 'styled-components';

export const Background = styled.div`
    background: url(${props => props.src});
    background-size: cover;
    background-position: bottom;

    @media all and (min-width: 1440px) {
        margin-top: -300px;
    }

    @media all and (min-width: 1600px) {
        margin-top: -400px;
    }

    @media all and (min-width: 1920px) {
        margin-top: -500px;
    }

    @media all and (min-width: 2300px) {
        margin-top: -600px;
    }

    @media all and (max-width: 1300) {
        margin-top: -200px;
    }

    @media all and (max-width: 1200px) {
        margin-top: 100px;
    }

    @media all and (max-width: 875px) {
        margin-top: 200px;
    }

    @media all and (max-width: 580px) {
        margin-top: 350px;
    }
`;
